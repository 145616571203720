import * as React from "react";
import { useMemo } from "react";
import { LocalArea, LocalAreaKey, getLocalArea } from "../../config/LocalArea";
import OwnerLpIndex, { OwnerLpIndexProps } from "./OwnerLpIndex";
import { getCanonicalUrl } from "../../config/constants";

type Props = {
  areaKey: LocalAreaKey;
};

/**
 * 広告ID付きページの飼い主向けLPのレイアウトです。
 *
 * https://wanpass.me/hotel などのページで使われます。
 */
export function LocalAreaOwnerLpIndex({ areaKey }: Props) {
  const area = useMemo<LocalArea>(() => getLocalArea(areaKey), [areaKey]);
  const props = useMemo<OwnerLpIndexProps>(() => toLpProps(area), [area]);

  return <OwnerLpIndex {...props}>{area.name}</OwnerLpIndex>;
}

const toLpProps = (area: LocalArea): OwnerLpIndexProps => {
  return {
    seo: {
      title: area.title,
      noTitleTemplate: true,
      canonical: getCanonicalUrl("/"), // /hotel, /food などのパスもすべて / に統一。
    },
    area,
  };
};
